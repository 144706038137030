<template>
    <div class="mobile-box">
        <div class="mobile-container">
            <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-image" />
            <h2 class="mobile-title">Informe um número de celular</h2>
            <p class="instruction">
                Por favor, informe um número de celular que tenha uma conta no WhatsApp. Esse será o número utilizado
                para conversas com a AI.
            </p>
            <div class="input-container">
                <label for="country-code">Código do País</label>
                <select v-model="countryCode" class="input-field" @change="updateMask">
                    <option value="55">Brasil (+55)</option>
                    <option value="351">Portugal (+351)</option>
                </select>
            </div>
            <div class="input-container">
                <label for="mobile-number">Número de Celular</label>
                <input type="text" v-model="mobileNumber" placeholder="Digite seu número de celular" class="input-number-field" v-mask="mobileMask"  />
            </div>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
            <div class="button-container">
                <button class="previous-button" @click="goBack">Anterior</button>
                <button class="next-button" @click="goNext">Próximo</button>
            </div>
        </div>
    </div>
</template>

<script>

import { mask } from 'vue-the-mask';

export default {
    data() {
        return {
            mobileMask: ['(##) #####-####'],
            countryCode: '55',
            mobileNumber: '',
            errorMessage: ''
        };
    },
    directives: { mask },
    methods: {
        validateNumber() {
            
            const brazilianPhoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/;
            const portuguesePhoneRegex = /^\d{3} \d{3} \d{3}$/;

            if (!this.countryCode) {
                this.errorMessage = 'Por favor, selecione um código de país.';
                return false;
            }

            if (!this.mobileNumber) {
                this.errorMessage = 'Por favor, insira o número de celular.';
                return false;
            }

            if (this.countryCode === '55') {
                if (!brazilianPhoneRegex.test(this.mobileNumber)) {
                    this.errorMessage = 'Por favor, informe um número de celular válido no formato brasileiro.';
                    return false;
                } else {
                    this.errorMessage = '';
                    return true;
                }
            } else if (this.countryCode === '351') {
                if (!portuguesePhoneRegex.test(this.mobileNumber)) {
                    this.errorMessage = 'Por favor, informe um número de celular válido no formato português.';
                    return false;
                } else {
                    this.errorMessage = '';
                    return true;
                }
            } else {
                this.errorMessage = 'Código de país não suportado para validação.';
                return false;
            }
        },
        submitNumber() {
            if (this.validateNumber()) {
                const phoneNumber = {
                    mobileNumber: this.mobileNumber.replaceAll(/\s|\+|\(|\)|-/g, ""),
                    countryCode: this.countryCode
                }
                this.$emit('phonenumber-changed', phoneNumber);
                return true;
            }
            return false
        },
        goBack() {
            this.$emit('update-previous-step');
        },
        goNext() {
            if(this.submitNumber()){
                this.$emit('update-next-step');
            }
        },
        updateMask() {
            this.mobileNumber = '';
            this.mobileMask = this.countryCode == "55"
                ? ['(##) ####-####', '(##) #####-####']
                : '### ### ###';
        }
    }
};
</script>

<style scoped>
.mobile-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
}

.mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 700px;
    height: 630px;
    max-height: 80%;
    max-width: 90%;
    justify-content: center;
    background-color: #f0f2f5;
}

.company-image {
    max-width: 100px;
    margin-bottom: 20px;
}

.mobile-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.instruction {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.input-container {
    width: 100%;
    margin-bottom: 15px;
}

label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.input-field {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.input-number-field {
    width: 96%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.error {
    color: #ff4d4f;
    background: #fff3f4;
    padding: 10px;
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 96%;
    text-align: center;
    font-size: 0.9em;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.previous-button,
.next-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.previous-button:hover,
.next-button:hover {
    background-color: #45a049;
}

div {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
}
</style>