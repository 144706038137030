import { createRouter, createWebHistory } from "vue-router";
import LoginComponent from "@/components/LoginComponent.vue";
import ProfileComponent from "@/components/ProfileComponent.vue";
import RegistrationComponent from "@/components/RegistrationComponent.vue";
import InitialFlowComponent from "@/components/InitialFlowComponent.vue";

const routes = [
  {
    path: "/",
    name: "LoginInitial",
    component: LoginComponent,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginComponent,
  },
  {
    path: "/profile",
    name: "Sua página",
    component: ProfileComponent,
  },
  {
    path: "/registration",
    name: "Registro",
    component: RegistrationComponent,
  },
  {
    path: "/wizard",
    name: "Início",
    component: InitialFlowComponent,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
