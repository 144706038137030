<template>

    <WelcomePageComponent v-if="step == 0" @update-next-step="nextStep"></WelcomePageComponent>
    <InformPasswordComponent v-if="step == 1" @update-next-step="nextStep" @update-previous-step="previousStep"
        @password-changed="setCustomerPassword"></InformPasswordComponent>
    <MobileNumberComponent v-if="step == 2" @update-next-step="nextStep" @update-previous-step="previousStep"
        @phonenumber-changed="setUserMobilePhone"></MobileNumberComponent>
    <PreferencesInitialComponent v-if="step == 3" @update-next-step="nextStep" @update-previous-step="previousStep"
        @preferences-selected="setCustomerPreferences">
    </PreferencesInitialComponent>

    <ConfirmPersonalDataComponent v-if="step == 4" @finish-wizard="finishRegister" @update-previous-step="previousStep"
        @customer-data="setCustomerData">
    </ConfirmPersonalDataComponent>

    <CongratulateComponent v-if="step == 5"></CongratulateComponent>

    <circular-progress-bar ref="loadingDots" />
    <vue3-snackbar bottom right :duration="4000"></vue3-snackbar>

</template>

<script>

import InformPasswordComponent from '@/components/InformPasswordComponent.vue'
import WelcomePageComponent from '@/components/WelcomePageComponent.vue'
import MobileNumberComponent from '@/components/MobileNumberComponent.vue'
import PreferencesInitialComponent from '@/components/PreferencesInitialComponent.vue';
import ConfirmPersonalDataComponent from '@/components/ConfirmPersonalDataComponent.vue';
import CongratulateComponent from '@/components/CongratulateComponent.vue';
import CircularProgressBar from '@/components/CircularProgressBar.vue';

import { mapGetters } from "vuex";
import api from "@/services/api.js";
import { Vue3Snackbar } from "vue3-snackbar";

export default {
    components: {
        InformPasswordComponent,
        WelcomePageComponent,
        MobileNumberComponent,
        PreferencesInitialComponent,
        ConfirmPersonalDataComponent,
        CongratulateComponent,
        CircularProgressBar,
        Vue3Snackbar
    },
    computed: {
        ...mapGetters(["getToken", "getUserId"])
    },
    mounted() {
        let token = this.getToken;
        if (!token) {
            this.redirectToLogin();
            return;
        }

    },
    data() {
        return {
            step: 0,
            customer: {
                id: "",
                name: "",
                lastname: "",
                mobile: "",
                language: "",
                countryCode: "55",
                user: {
                    email: "",
                    password: ""
                },
                preferences: [],
            }
        }
    },
    methods: {
        nextStep() {
            this.step++;
        },
        previousStep() {
            this.step--;
        },
        redirectToLogin() {
            this.$router.push('/login');
        },
        setCustomerPassword(password) {
            this.customer.user.password = password;
        },
        setUserMobilePhone(mobile) {
            this.customer.countryCode = mobile.countryCode;
            this.customer.mobile = mobile.mobileNumber;
        },
        setCustomerPreferences(preferences) {
            this.customer.preferences = preferences;
        },
        setCustomerData(customer) {
            this.customer.id = customer.id;
            this.customer.name = customer.name;
            this.customer.lastname = customer.lastname;
            this.customer.user.email = customer.user.email;
        },
        finishRegister() {
            this.startLoading();
            let customerId = this.customer.id;
            let token = this.getToken;

            this.customer.user.firstLogin = false;

            api.put(`customers/${customerId}`, { customer: this.customer }, { headers: { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    if(response.status == 204){
                        this.stopLoading();
                        this.nextStep();
                    } else {
                        this.errorMessage();
                        this.stopLoading();
                    }
                }).catch((response) => {
                    console.error(response);
                    this.errorMessage();
                    this.stopLoading();
                });
        },
        startLoading() {
            this.$refs.loadingDots.show();
        },
        stopLoading() {
            this.$refs.loadingDots.hide();
        },
        errorMessage() {
            this.$snackbar.add({
                type: 'error',
                text: 'Erro inesperado! Contacte o administrador'
            })
        }
    }

}

</script>

<style></style>